@tailwind base;
@tailwind components;
@tailwind utilities;

.rv-y-plot__grid-lines__line {
  stroke: gray;
  stroke-width: 1;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}
@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
@import "~react-vis/dist/style";
